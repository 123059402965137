import { Group, Text, useMantineTheme, rem } from '@mantine/core';
import { IconUpload, IconPhoto, IconX } from '@tabler/icons-react';
import { Dropzone, DropzoneProps, FileWithPath, MIME_TYPES } from '@mantine/dropzone';
import BackendClient from './client/backentClient';
import { notifications } from '@mantine/notifications';

interface FileUploaderProps {
    caseId: string,
    userId: string
}

function FileUploader(props: FileUploaderProps & Partial<DropzoneProps>) {
    const backendClient = new BackendClient();
    const theme = useMantineTheme();

    const uploadFiles = (files: FileWithPath[]) => {
        files.map((f) => uploadFile(f));
    }

    const uploadFile = async (file: FileWithPath) => {
        console.log(file);
        try {
            await backendClient.uploadFile(props.caseId, props.userId, file);
            notifications.show({
                title: 'Upload completed!',
                message: file.name,
                autoClose: 5000,
            })
        } catch (e) {
            notifications.show({
                title: 'Upload failed: ' + e,
                message: file.name,
                autoClose: 5000,
            })
        }
    }

    return (
        <>
            <Dropzone
                onDrop={(files) => uploadFiles(files)}
                onReject={(files) => console.log('rejected files', files)}
                maxSize={5 * 1024 ** 2}
                accept={[
                    MIME_TYPES.png,
                    MIME_TYPES.gif,
                    MIME_TYPES.jpeg,
                    MIME_TYPES.svg,
                    MIME_TYPES.webp,
                    MIME_TYPES.avif,
                    MIME_TYPES.mp4,
                    MIME_TYPES.zip,
                    MIME_TYPES.csv,
                    MIME_TYPES.pdf,
                    MIME_TYPES.doc,
                    MIME_TYPES.docx,
                    MIME_TYPES.xls,
                    MIME_TYPES.xlsx,
                    MIME_TYPES.ppt,
                    MIME_TYPES.pptx,
                    "text/plain"
                ]}
                {...props}
            >
                <Group position="center" spacing="xl" style={{ minHeight: rem(220), pointerEvents: 'none' }}>
                    <Dropzone.Accept>
                        <IconUpload
                            size="3.2rem"
                            stroke={1.5}
                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                        />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                        <IconX
                            size="3.2rem"
                            stroke={1.5}
                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                        />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                        <IconPhoto size="3.2rem" stroke={1.5} />
                    </Dropzone.Idle>

                    <div>
                        <Text size="xl" inline>
                            Drag images here or click to select files
                        </Text>
                        <Text size="sm" color="dimmed" inline mt={7}>
                            Attach as many files as you like, each file should not exceed 5mb
                        </Text>
                    </div>
                </Group>
            </Dropzone>
        </>
    );
}

export default FileUploader;