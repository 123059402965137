import { LoadingOverlay, TextInput } from "@mantine/core";
import { IFile } from "./client/model";
import { useEffect, useState } from "react";
import BackendClient from "./client/backentClient";
import { RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from "@tiptap/starter-kit";
import { useDisclosure } from "@mantine/hooks";

interface AiAssistantProps {
    caseId: string,
    userId: string,
    files: IFile[],
}
function AiAssistant({ caseId, userId, files }: AiAssistantProps) {
    const backendClient = new BackendClient();
    const [prompt, setPrompt] = useState('');
    const [content, setContent] = useState<string>('');
    const [connection, setConnection] = useState<WebSocket | null>(null);
    const [disableInput, setdisableInput] = useState(true);
    const [loadingWheelVisible, loadingWheelHandlers] = useDisclosure(false);

    const editor = useEditor({
        extensions: [
            StarterKit,
        ],
        editable: false,
        content,
    }, [content]);

    let ws: WebSocket;
    useEffect(() => {
        loadingWheelHandlers.open();
        const protocol = backendClient.secure ? "wss://" : "ws://";
        ws = new window.WebSocket(`${protocol}${backendClient.backendDomain}/chat/${caseId}`);

        // Connection opened
        ws.onopen = () => {
            setConnection(ws);
            loadingWheelHandlers.close();
            setdisableInput(false);
        };

        ws.onmessage = (event: MessageEvent) => {
            console.log("Message from server ", event.data);
            setContent((t) => `${t}${event.data}`)
            setdisableInput(false);
        };

        return () => {
            ws.close(); // Close the connection when the component unmounts
        };
    }, []);

    const maybeSubmit = (key: string) => {
        if (key != "Enter") {
            return
        }
        if (connection == null) {
            console.log("WS not connected")
            return
        }
        setdisableInput(true);
        connection.send(prompt);
        setPrompt("")
    }

    return (
        <>
            <LoadingOverlay visible={loadingWheelVisible} zIndex={1000} />
            <RichTextEditor editor={editor}>
                <RichTextEditor.Content />
            </RichTextEditor>
            <TextInput
                placeholder="Enter a prompt"
                value={prompt}
                onChange={(event) => setPrompt(event.currentTarget.value)}
                onKeyDown={(event) => maybeSubmit(event.key)}
                disabled={disableInput}
            />
        </>
    );
}

export default AiAssistant;
