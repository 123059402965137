import { useForm, isNotEmpty, isEmail } from '@mantine/form';
import { Button, Group, TextInput, Box } from '@mantine/core';
import { IContactInput } from './client/model';

interface CounterpartInputFormProps {
    onSubmit: (newCounterparts: IContactInput[]) => void
}

function CounterpartInputForm({ onSubmit }: CounterpartInputFormProps) {
    const form = useForm({
        initialValues: {
            name: 'name',
            email: 'email@test.com',
            phone: '0000000000',
            street: 'street',
            number: 'number',
            city: 'city',
            state: 'state',
            zip: 'zip',
            country: 'country'
        },

        validate: {
            name: isNotEmpty('Enter the counterpart name'),
            email: isEmail('Invalid email'),
            phone: isNotEmpty('Enter the counterpart phone'),
            street: isNotEmpty('Enter the counterpart street'),
            number: isNotEmpty('Enter the counterpart number'),
            city: isNotEmpty('Enter the counterpart city'),
            state: isNotEmpty('Enter the counterpart state'),
            zip: isNotEmpty('Enter the counterpart zip'),
            country: isNotEmpty('Enter the counterpart country')
        },
    });

    return (
        <Box component="form" maw={400} mx="auto" onSubmit={form.onSubmit((values: IContactInput) => onSubmit([values]))}>
            <TextInput
                label="Name"
                withAsterisk
                mt="md"
                {...form.getInputProps('name')}
            />
            <TextInput
                label="Email"
                withAsterisk
                mt="md"
                {...form.getInputProps('email')}
            />
            <TextInput
                label="Phone"
                withAsterisk
                mt="md"
                {...form.getInputProps('phone')}
            />
            <TextInput
                label="Street"
                withAsterisk
                mt="md"
                {...form.getInputProps('street')}
            />
            <TextInput
                label="Number"
                withAsterisk
                mt="md"
                {...form.getInputProps('number')}
            />
            <TextInput
                label="City"
                withAsterisk
                mt="md"
                {...form.getInputProps('city')}
            />
            <TextInput
                label="State"
                withAsterisk
                mt="md"
                {...form.getInputProps('state')}
            />
            <TextInput
                label="Zip"
                withAsterisk
                mt="md"
                {...form.getInputProps('zip')}
            />
            <TextInput
                label="Country"
                withAsterisk
                mt="md"
                {...form.getInputProps('country')}
            />
            <Group position="right" mt="md">
                <Button type="submit">Submit</Button>
            </Group>
        </Box>
    );
}

export default CounterpartInputForm;