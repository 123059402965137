import {
  Avatar,
  Table,
  Group,
  Text,
  ActionIcon,
  Anchor,
  ScrollArea
} from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { IUser } from './client/model';
import { modals } from '@mantine/modals';

interface UsersTableProps {
  users: IUser[];
  onDelete: (userIds: string[]) => void;
}

export function UsersTable({ users, onDelete }: UsersTableProps) {
  const openModal = (user: IUser) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure you want to remove {user.contact.name}?
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => onDelete([user.id]),
  });

  const rows = users.map((user) => (
    <tr key={user.id}>
      <td>
        <Group spacing="sm">
          <Avatar size={30} src={user.avatar} radius={30} />
          <Text fz="sm" fw={500}>
            {user.contact.name}
          </Text>
        </Group>
      </td>
      <td>
        <Anchor component="button" size="sm">
          {user.contact.email}
        </Anchor>
      </td>
      <td>
        <Text fz="sm" c="dimmed">
          {user.contact.phone}
        </Text>
      </td>
      <td>
        <Group spacing={0} position="right">
          <ActionIcon color="red" onClick={() => openModal(user)}>
            <IconTrash size="1rem" stroke={1.5} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  return (
    <ScrollArea>
      <Table verticalSpacing="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
}