import { Text, Timeline, Tooltip } from '@mantine/core';
import { IconAtom, IconEditCircle, IconFile, IconFileDollar, IconUser, IconUserCircle, IconUsers } from '@tabler/icons-react';
import { ICaseEvent } from './client/model';
import moment from 'moment';

interface IconInfo {
    icon: JSX.Element;
    title: string;
}
const iconSize = 18;
const icons: Record<string, IconInfo> = {
    CaseCreated:
    {
        icon: <IconAtom size={iconSize} />,
        title: "Case created"
    },
    CaseNameUpdated:
    {
        icon: <IconEditCircle size={iconSize} />,
        title: "Case name updated"
    },
    CaseAssigneesUpdated:
    {
        icon: <IconUser size={iconSize} />,
        title: "Assignees updated"
    },
    CaseClientsUpdated:
    {
        icon: <IconUsers size={iconSize} />,
        title: "Clients updated"
    },
    CaseCounterpartsUpdated:
    {
        icon: <IconUserCircle size={iconSize} />,
        title: "Counterparts updated"
    },
    CaseExpensesUpdated:
    {
        icon: <IconFileDollar size={iconSize} />,
        title: "Expenses updated"
    },
    CaseDocumentUploaded:
    {
        icon: <IconFile size={iconSize} />,
        title: "Document uploaded"
    },
};

const eventTimelineItem = (event: ICaseEvent, dashedLined?: boolean) => {
    return (
        <Timeline.Item bullet={icons[event.type.type].icon} title={icons[event.type.type].title} lineVariant={dashedLined ? "dashed" : "solid"}>
            <Text color="dimmed" size="sm">{event.description}</Text>
            <Tooltip
                label={moment(event.createdAt).format('llll')}
                position="left"
                withArrow
            >
                <Text size="xs" mt={4}>{moment(event.createdAt).fromNow()}</Text>
            </Tooltip>
        </Timeline.Item>
    )
}

interface CaseEventTimelineProps {
    events: ICaseEvent[];
}

function CaseEventTimeline({ events }: CaseEventTimelineProps) {
    return (
        <>
            <Timeline active={1} bulletSize={24} lineWidth={2}>
                <Text fz="xl" align="left">Timeline</Text>
                {events.slice(0, 2).map((event) => (
                    eventTimelineItem(event)
                ))}
                {events.slice(3, 4).map((event) => (
                    eventTimelineItem(event, true)
                ))}
                {events.length > 2 && events.slice(events.length - 1, events.length).map((event) => (
                    eventTimelineItem(event)
                ))}
            </Timeline>
        </>
    );
}

export default CaseEventTimeline;
