import { StatsSegments } from "./StatsSegments";
import { IExpense } from "./client/model";

interface ExpensesProps {
    expenses: IExpense[];
}

export function Expenses({ expenses }: ExpensesProps) {

    const data: any = {};

    const total = expenses.reduce(
        (acc, val) => acc + val.amount,
        0
    );
    data.total = "$" + total;

    const grouped = new Map<string, IExpense[]>();
    expenses.forEach((e) => {
        let updated = grouped.get(e.operation.toLowerCase());
        if (updated === undefined) {
            updated = [];
        }
        updated.push(e);
        grouped.set(e.operation.toLowerCase(), updated);
    })

    data.data = [];
    grouped.forEach((value: IExpense[], key: string) => {
        const localTotal = value.reduce(
            (acc, val) => acc + val.amount,
            0
        );
        const obj = {
            "label": key,
            "count": "$" + localTotal,
            "part": Number((localTotal / total * 100).toFixed(1)),
            "color": "#" + Math.floor(Math.random() * 16777215).toString(16)
        }
        data.data.push(obj);
    });

    return (
        <>
            <StatsSegments total={data.total} data={data.data}></StatsSegments>
        </>
    );
}