import { Avatar, Container, Group, ScrollArea, Table, Text, createStyles, rem } from '@mantine/core';
import moment from 'moment';
import { useState } from 'react';
import './App.css';
import BackendClient from './client/backentClient';
import { IFile } from './client/model';
import { IconFileDownload } from '@tabler/icons-react';

interface FilesProps {
  caseId: string;
  files: IFile[];
}

const backendClient = new BackendClient();

const downloadFile = async (caseId: string, fileId: string) => {
  const link = document.createElement('a');
  link.download = 'Example-PDF-File';
  link.href = await backendClient.downloadFile(caseId, fileId);
  link.click();
}

const formatBytes = (bytes: number, decimals: number) => {
  if (bytes === 0) return '0 Bytes';
  var k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease',

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
        }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
}));

function TableScrollArea(caseId: string, files: IFile[]) {
  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);

  const rows = files.map((f) => (
    <tr key={f.name}>
      <td>{f.name}</td>
      <td>{formatBytes(f.size, 2)}</td>
      <td>{moment(f.createdAt).format('llll')}</td>
      <td>
        <Group spacing="sm">
          <Avatar size={30} src={f.createdBy.avatar} radius={30} />
          <Text fz="sm" fw={500}>
            {f.createdBy.contact.name}
          </Text>
        </Group>
      </td>
      <td>
        <IconFileDownload onClick={() => downloadFile(caseId, f.id)}/>
        </td>
    </tr>
  ));

  return (
    <>
      <ScrollArea h={300} onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
        <Table miw={700} striped highlightOnHover withColumnBorders>
          <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
            <tr>
              <th>File Name</th>
              <th>File size</th>
              <th>Uploaded at</th>
              <th>Uploaded by</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "left", cursor: "pointer" }}>{rows}</tbody>
        </Table>
      </ScrollArea>
    </>
  );
}

function Files({ caseId, files }: FilesProps) {
  return (
    <>
      <Container>
        {TableScrollArea(caseId, files)}
      </Container>
    </>
  );
}

export default Files;
