import { Button, Container, MultiSelect } from "@mantine/core";
import BackendClient from "./client/backentClient";
import { useState, useEffect } from "react";
import { IUsers } from "./client/model";

const backendClient = new BackendClient();

interface MultiSelectUsersProps {
  onSave: (userIds: string[]) => void;
  onClose: () => void;
  filterOut?: string[];
  maxSelectedValues?: number;
}

function MultiSelectUsers({
  onSave,
  onClose,
  filterOut,
  maxSelectedValues
}: MultiSelectUsersProps) {
  const [findRes, setfindRes] = useState<IUsers>();
  const [value, setValue] = useState<string[]>([]);

  useEffect(() => {
    backendClient.findUsers().then((res) => setfindRes(res))
  }, []);

  const transform = (usersRes: IUsers | undefined) => {
    if (usersRes === undefined) {
      return [];
    }
    return usersRes.users
      .filter((user) => !filterOut?.includes(user.id))
      .map((user) => (
        { value: user.id, label: user.contact.name }
      ));
  }

  const onSaveButtonClick = () => {
    onSave(value);
    onClose();
  }

  return (
    <>
      <MultiSelect
        // https://github.com/mantinedev/mantine/issues/448
        zIndex={1000}
        data={transform(findRes)}
        value={value}
        onChange={setValue}
        maxSelectedValues={maxSelectedValues}
        label="Pick users"
      />
      <Container>
        <Button mt="md" mr="sm" variant="default" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button mt="md" onClick={() => onSaveButtonClick()}>
          Save
        </Button>
      </Container>
    </>
  )
}

export default MultiSelectUsers;