import { ActionIcon, Button, Center, Container, Divider, Flex, Grid, Group, Indicator, Modal, SimpleGrid, Text } from '@mantine/core';
import { IconBrandWechat, IconCirclePlus, IconEdit, IconFiles } from '@tabler/icons-react';
import moment from 'moment';
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import EditableText from "./EditableText";
import MultiSelectUsers from "./MultiSelectUsers";
import { UsersTable } from "./UsersTable";
import BackendClient from "./client/backentClient";
import { ICase, IContactInput, IExpenseInput, IUpdateCase } from "./client/model";
import { modals } from '@mantine/modals';
import Avatars from './Avatars';
import CaseEventTimeline from './CaseEventTimeline';
import CounterpartInputForm from './CounterpartInputForm';
import { CounterpartsTable } from './CounterpartsTable';
import { Expenses } from './Expenses';
import ExpenseInputForm from './ExpenseInputForm';
import { useState } from 'react';
import FileManager from './FileManager';
import { useDisclosure } from '@mantine/hooks';
import AiAssistant from './AiAssistant';

const backendClient = new BackendClient();
let caseId: string;

export async function loader({ params }: LoaderFunctionArgs) {
  if (params.caseId === undefined) {
    throw new Error("no caseId passed")
  }
  caseId = params.caseId;
  const caze = await backendClient.getCase(caseId);
  return caze;
}


function Case() {
  const backendClient = new BackendClient();
  const [caze, setCaze] = useState(useLoaderData() as ICase);

  const [filesModalOpened, { open: filesModalOpen, close: filesModalClose }] = useDisclosure(false);
  
  const [aiAssistantModalOpened, { open: aiAssistantModalOpen, close: aiAssistantModalClose }] = useDisclosure(false);

  const refreshCaze = async () => {
    const caze = await backendClient.getCase(caseId);
    setCaze(caze);
  }

  const refreshCazeWithDelay = async () => {
    setTimeout(function () { refreshCaze(); }, 1000);
  }

  const onCaseNameChange = (text: string) => {
    const updates: IUpdateCase = {
      updatingUserId: caze.createdBy.id, // TODO use logged in user instead
      name: text
    }
    backendClient.updateCase(caze.id, updates);
    refreshCazeWithDelay();
  }

  const onAddAssignees = (newAssignees: string[]) => {
    const updates: IUpdateCase = {
      updatingUserId: caze.createdBy.id, // TODO use logged in user instead
      addAssignees: newAssignees
    }
    backendClient.updateCase(caze.id, updates);
    refreshCazeWithDelay();
  }

  const onRemoveAssignees = (removeAssignees: string[]) => {
    const updates: IUpdateCase = {
      updatingUserId: caze.createdBy.id, // TODO use logged in user instead
      removeAssignees: removeAssignees
    }
    backendClient.updateCase(caze.id, updates);
  }

  const onAddClients = (newClients: string[]) => {
    const updates: IUpdateCase = {
      updatingUserId: caze.createdBy.id, // TODO use logged in user instead
      addClients: newClients
    }
    backendClient.updateCase(caze.id, updates);
    refreshCazeWithDelay();
  }

  const onRemoveClients = (removeClients: string[]) => {
    const updates: IUpdateCase = {
      updatingUserId: caze.createdBy.id, // TODO use logged in user instead
      removeClients: removeClients
    }
    backendClient.updateCase(caze.id, updates);
    refreshCazeWithDelay();
  }

  const onAddCounterparts = (newCounterparts: IContactInput[]) => {
    const updates: IUpdateCase = {
      updatingUserId: caze.createdBy.id, // TODO use logged in user instead
      addCounterparts: newCounterparts
    }
    backendClient.updateCase(caze.id, updates);
    modals.closeAll();
    refreshCazeWithDelay();
  }

  const onRemoveCounterparts = (removeCounterparts: string[]) => {
    const updates: IUpdateCase = {
      updatingUserId: caze.createdBy.id, // TODO use logged in user instead
      removeCounterparts: removeCounterparts
    }
    backendClient.updateCase(caze.id, updates);
    modals.closeAll();
    refreshCazeWithDelay();
  }

  const onAddExpenses = (newExpenses: IExpenseInput[]) => {
    const updates: IUpdateCase = {
      updatingUserId: caze.createdBy.id, // TODO use logged in user instead
      addExpenses: newExpenses
    }
    backendClient.updateCase(caze.id, updates);
    modals.closeAll();
    refreshCazeWithDelay();
  }

  return (
    <>
      <Container>
        <Container>
          <Center>
            <EditableText initialText={caze.name} fz="xl" fw={700} onTextChange={onCaseNameChange}></EditableText>
          </Center>
          <SimpleGrid cols={3} spacing="xs" verticalSpacing="xs">
            <Group>
              <Text>next deadline {moment(caze.nextDeadline).fromNow()}, on {moment(caze.nextDeadline).format('llll')}</Text>
            </Group>
            <Container>
              <Flex>
                <Text>assignees</Text>
                <Center>
                  <ActionIcon variant="subtle">
                    <IconEdit
                      size="1rem"
                      onClick={() => {
                        modals.open({
                          title: 'Modify assignees',
                          children: (
                            <>
                              <UsersTable
                                users={caze.assignees}
                                onDelete={onRemoveAssignees}
                              />
                              <MultiSelectUsers
                                onSave={onAddAssignees}
                                onClose={modals.closeAll}
                                filterOut={caze.assignees.map((u) => u.id)}
                              />
                            </>
                          ),
                        });
                      }}
                    />
                  </ActionIcon>
                </Center>
              </Flex>
              <Avatars users={caze.assignees} />
            </Container>
          </SimpleGrid>
        </Container>
        <Divider my="sm" />
        <SimpleGrid cols={2} spacing="xl" verticalSpacing="xl" breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
          <CaseEventTimeline events={caze.events}></CaseEventTimeline>
          <Grid gutter="md">
            <Grid.Col>
              <Flex>
                <Text fz="xl" align="left">Expenses</Text>
                <Center>
                  <ActionIcon
                    onClick={() => {
                      modals.open({
                        title: 'Add an expense',
                        children: (
                          <>
                            <ExpenseInputForm
                              onSubmit={onAddExpenses}
                            />
                          </>
                        ),
                      });
                    }}
                    variant="subtle">
                    <IconCirclePlus size="1rem" />
                  </ActionIcon>
                </Center>
              </Flex>
              <Expenses expenses={caze.expenses} />
            </Grid.Col>
            <Grid.Col span={6}>
              <Modal opened={filesModalOpened} onClose={filesModalClose} title="Files" size="60%">
                {/* TODO use logged in user instead */}
                <FileManager caseId={caseId} userId={caze.createdBy.id} files={caze.files}></FileManager>
              </Modal>
              <Text fz="xl" align="center">Documents</Text>
              <Center mx="auto" h={75}>
                <ActionIcon size="xl" radius="md" variant="outline" mx="auto" my="auto"
                  onClick={() => filesModalOpen()}>
                  <IconFiles size="5rem" />
                </ActionIcon>
              </Center>
              <Text fz="md">{caze.files.length} documents</Text>
            </Grid.Col>
            <Grid.Col span={6}>
              <Text fz="xl" align="center">Chats</Text>
              <Center mx="auto" h={75}>
                <Indicator>
                  <ActionIcon size="xl" radius="md" variant="outline" mx="auto" my="auto">
                    <IconBrandWechat size="5rem" />
                  </ActionIcon>
                </Indicator>
              </Center>
              <Text fz="md">2 unread messages</Text>
            </Grid.Col>
          </Grid>
          <div>
            <Flex>
              <Text fz="xl" align="left">Clients</Text>
              <Center>
                <ActionIcon variant="subtle">
                  <IconCirclePlus size="1rem"
                    onClick={() => {
                      modals.open({
                        withCloseButton: false,
                        children: (
                          <>
                            <MultiSelectUsers
                              onSave={onAddClients}
                              onClose={modals.closeAll}
                              filterOut={caze.clients.map((u) => u.id)}
                            />
                          </>
                        ),
                      });
                    }}
                  />
                </ActionIcon>
              </Center>
            </Flex>
            <UsersTable
              users={caze.clients}
              onDelete={onRemoveClients}
            />
          </div>
          <Grid gutter="md">
            <Grid.Col>
              <Flex>
                <Text fz="xl" align="left">Counterparts</Text>
                <Center>
                  <ActionIcon
                    onClick={() => {
                      modals.open({
                        title: 'Add a counterpart',
                        children: (
                          <>
                            <CounterpartInputForm
                              onSubmit={onAddCounterparts}
                            />
                          </>
                        ),
                      });
                    }}
                    variant="subtle">
                    <IconCirclePlus size="1rem" />
                  </ActionIcon>
                </Center>
              </Flex>
              <CounterpartsTable
                counterparts={caze.counterparts}
                onDelete={onRemoveCounterparts}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span="auto">
            <Modal opened={aiAssistantModalOpened} onClose={aiAssistantModalClose} title="GPT" size="60%">
                {/* TODO use logged in user instead */}
                <AiAssistant caseId={caseId} userId={caze.createdBy.id} files={caze.files}></AiAssistant>
              </Modal>
              <Button variant="filled" onClick={() => aiAssistantModalOpen()}>AI assistant</Button>
            </Grid.Col>
          </Grid>
        </SimpleGrid>
      </Container>
    </>
  );
}

export default Case;



