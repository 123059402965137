import { Tooltip, Avatar } from "@mantine/core";
import { IUser } from "./client/model";

interface AvatarsProps {
  users: IUser[];
}

function Avatars({ users }: AvatarsProps) {
  const display = users.slice(0, 3);
  const nameOnly = users.slice(3);
  return (
    <Tooltip.Group openDelay={300} closeDelay={100}>
      <Avatar.Group spacing="sm">
        {display.map((u) =>
          <Tooltip label={u.contact.name} withArrow>
            <Avatar src={u.avatar} radius="xl" />
          </Tooltip>
        )}
        {nameOnly.length > 0 &&
          <Tooltip
            withArrow
            label={
              <>
                {nameOnly.map((u) =>
                  <div>{u.contact.name}</div>
                )}
              </>
            }
          >
            <Avatar radius="xl">+{nameOnly.length}</Avatar>
          </Tooltip>
        }
      </Avatar.Group>
    </Tooltip.Group>
  );
}

export default Avatars;