import { Badge, Button, Container, Flex, Loader, LoadingOverlay, Modal, ScrollArea, Table, TextInput, createStyles, rem } from '@mantine/core';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './App.css';
import Avatars from './Avatars';
import BackendClient from './client/backentClient';
import { ICases } from './client/model';
import { useDisclosure, useInputState } from '@mantine/hooks';

const backendClient = new BackendClient();

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease',

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
        }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
}));

function TableScrollArea(findRes: ICases | undefined) {
  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const [loadingWheelVisible, { toggle: toggleLoadingWheel }] = useDisclosure(false);


  const navigate = useNavigate();
  const handleRowClick = (caseId: string) => {
    toggleLoadingWheel();
    navigate(`/case/${caseId}`);
  }

  if (findRes === undefined) {
    return <div>
      <Loader />
      <br/>
      Loading
    </div>
  }

  const badgeColors: Record<string, string> = {
    Open: 'blue',
    Close: 'gray',
  };

  const rows = findRes.cases.map((c) => (
    <tr key={c.id} onClick={() => handleRowClick(c.id)}>
      <td>{c.name}</td>
      <td>{moment(c.nextDeadline).format('llll')}</td>
      <td><Avatars users={c.assignees} /></td>
      <td>{moment(c.lastUpdatedAt).format('llll')}</td>
      <td>
        <Badge
          color={badgeColors[c.status.type]}
        >
          {c.status.type}
        </Badge>
      </td>
    </tr>
  ));

  return (
    <>
      <LoadingOverlay visible={loadingWheelVisible} zIndex={1000} />
      <ScrollArea h={300} onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
        <Table miw={700} striped highlightOnHover withColumnBorders>
          <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
            <tr>
              <th>Name</th>
              <th>Next Deadline</th>
              <th>Assignees</th>
              <th>Last updated at</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "left", cursor: "pointer" }}>{rows}</tbody>
        </Table>
      </ScrollArea>
    </>
  );
}

function Cases() {
  const [findRes, setfindRes] = useState<ICases>();
  const [opened, { open, close }] = useDisclosure(false);
  const [newCaseName, setNewCaseName] = useInputState('');

  useEffect(() => {
    backendClient.findCases().then((res) => setfindRes(res))
  }, []);

  const createCase = async () => {
    console.log('create case with name ' + newCaseName);
    const req = {
      userId: findRes?.cases[0].createdBy.id || '', // TODO use logged user
      name: newCaseName,
    }
    await backendClient.createCase(req);
    close();
  }

  return (
    <>
      <Modal opened={opened} onClose={close} title="Authentication">
        <TextInput
          label="Case name"
          placeholder="case name"
          value={newCaseName}
          onChange={setNewCaseName}
        />
        <Button fullWidth onClick={createCase} mt="md">
          Create
        </Button>
      </Modal>
      <Container>
        <Flex
          justify="flex-end"
          align="center"
        >
          <Button
            mb={20}
            onClick={open}
          >
            Create a new case
          </Button>
        </Flex>
        {TableScrollArea(findRes)}
      </Container>
    </>
  );
}

export default Cases;
