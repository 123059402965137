import {
  Table,
  Group,
  Text,
  ActionIcon,
  Anchor,
  ScrollArea
} from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { ICounterpart } from './client/model';
import { modals } from '@mantine/modals';

interface CounterpartsTableProps {
  counterparts: ICounterpart[];
  onDelete: (counterpartsIds: string[]) => void;
}

export function CounterpartsTable({ counterparts, onDelete }: CounterpartsTableProps) {
  const openModal = (counterpart: ICounterpart) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure you want to remove {counterpart.contact.name}?
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => onDelete([counterpart.id]),
  });

  const rows = counterparts.map((counterpart) => (
    <tr key={counterpart.id}>
      <td>
        <Text fz="sm" fw={500}>
          {counterpart.contact.name}
        </Text>
      </td>
      <td>
        <Anchor component="button" size="sm">
          {counterpart.contact.email}
        </Anchor>
      </td>
      <td>
        <Text fz="sm" c="dimmed">
          {counterpart.contact.phone}
        </Text>
      </td>
      <td>
        <Group spacing={0} position="right">
          <ActionIcon color="red" onClick={() => openModal(counterpart)}>
            <IconTrash size="1rem" stroke={1.5} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  return (
    <ScrollArea>
      <Table verticalSpacing="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
}