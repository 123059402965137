import axios, { AxiosProgressEvent } from 'axios';
import { ICase, ICases, ICreateCase, IOllamaRequest, IOllamaResponse, IUpdateCase, IUsers } from './model';
import { FileWithPath } from '@mantine/dropzone';

class BackendClient {
    public static get LOCALHOST_DOMAIN(): string { return "localhost:8080"; }
    public static get DOMAIN(): string { return "lawtech-backend.scrapeo.com"; }

    backendDomain: string;
    backendUrl: string;
    secure: boolean;
    constructor() {
        // this.backendDomain = BackendClient.LOCALHOST_DOMAIN;
        // this.secure = false
        this.backendDomain = BackendClient.DOMAIN;
        this.secure = true;
        const protocol = this.secure ? "https://" : "http://";
        this.backendUrl = protocol + this.backendDomain;
    }

    async createCase(req: ICreateCase): Promise<string> {
        try {
            const { data } = await axios.post(`${this.backendUrl}/cases`, req);
            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error(error.status);
                console.error(error.response);
            } else {
                console.error(error);
            }
            throw new Error('PANIC')
        }
    }

    async getCase(caseId: string): Promise<ICase> {
        try {
            const { data } = await axios.get<ICase>(`${this.backendUrl}/case/${caseId}`);
            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error(error.status);
                console.error(error.response);
            } else {
                console.error(error);
            }
            throw new Error('PANIC')
        }
    }

    async updateCase(caseId: string, updates: IUpdateCase) {
        try {
            axios.post(`${this.backendUrl}/case/${caseId}`, updates)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error(error.status);
                console.error(error.response);
            } else {
                console.error(error);
            }
            throw new Error('PANIC')
        }
    }

    async findCases(): Promise<ICases> {
        try {
            const { data } = await axios.get<ICases>(`${this.backendUrl}/cases`);
            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error(error.status);
                console.error(error.response);
            } else {
                console.error(error);
            }
            throw new Error('PANIC')
        }
    }

    async findUsers(): Promise<IUsers> {
        try {
            const { data } = await axios.get<IUsers>(`${this.backendUrl}/users`);
            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error(error.status);
                console.error(error.response);
            } else {
                console.error(error);
            }
            throw new Error('PANIC')
        }
    }

    async uploadFile(caseId: string, userId: string, file: FileWithPath): Promise<boolean> {
        if (!file.path) {
            throw new Error('Invalid file ' + file);
        }
        const onUploadProgress = (event: AxiosProgressEvent) => {
            if (event.total) {
                const percentage = Math.round((100 * event.loaded) / event.total);
                console.log(percentage);
            }
        };

        try {
            let formData = new FormData();
            formData.append("caseId", caseId);
            formData.append("userId", userId);
            formData.append("filename", file.name);
            formData.append("file", file);
            const response = axios.post(`${this.backendUrl}/file/upload`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress,
            });
            console.log(response);
            return (await response).status == 200
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error(error.status);
                console.error(error.response);
            } else {
                console.error(error);
            }
            throw error
        }
    }

    async downloadFile(caseId: string, fileId: string) {
        try {
            const { data } = await axios.get<string>(`${this.backendUrl}/file/${caseId}/${fileId}`);
            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error(error.status);
                console.error(error.response);
            } else {
                console.error(error);
            }
            throw new Error('PANIC')
        }
    }

    async gpt(caseId: string, req: IOllamaRequest, updateFunction: (progressEvent: AxiosProgressEvent) => void) {
        try {
            const { data } = await axios.post(`${this.backendUrl}/gpt2/${caseId}`, req, {
                responseType: 'stream',
                onDownloadProgress: updateFunction,
            });

            //console.log(data);

            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error(error.status);
                console.error(error.response);
            } else {
                console.error(error);
            }
            throw new Error('PANIC')
        }
    }
}

export default BackendClient;