import './App.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createZitadelAuth, ZitadelConfig } from '@zitadel/react';
import { useEffect, useState } from 'react';
import Cases from './Cases';
import Case, {
  loader as caseLoader,
} from './Case';
import Login from './Login';
import Callback from './Callback';
import App from './App';

function AuthRouter() {

  const config: ZitadelConfig = {
    authority: "http://login.scrapeo.com/",
    client_id: "277355079395255549@lawtech",
  };

  const zitadel = createZitadelAuth(config);

  const login = () => {
    zitadel.authorize();
  }

  const signout = () => {
    zitadel.signout();
  }

  const [authenticated, setAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    zitadel.userManager.getUser().then((user) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    });
  }, [zitadel]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <App
        authenticated={authenticated}
        handleLogin={login}
        setAuth={setAuthenticated}
        handleLogout={signout}
        userManager={zitadel.userManager} />,
      children: [
        {
          path: "/",
          element: <Cases />,
        },
        {
          path: "/case/:caseId",
          element: <Case />,
          loader: caseLoader,
        },
        {
          path: "/login",
          element: <Login authenticated={authenticated} handleLogin={login} />,
        },
        {
          path: "/callback",
          element: <Callback
            authenticated={authenticated}
            setAuth={setAuthenticated}
            handleLogout={signout}
            userManager={zitadel.userManager}
          />,
        },
      ],
    },

  ]);


  const links = [
    { link: '/', 'label': 'Home' },
    { link: 'account', 'label': 'Account' }
  ]

  return (
    <RouterProvider router={router} />
  );
}

export default AuthRouter;
