import './App.css';
import { MantineProvider } from '@mantine/core';
import HeaderResponsive from './Header';
import { Outlet } from "react-router-dom";
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { UserManager, User } from "oidc-client-ts";
import { useEffect, useState } from "react";

type Props = {
  handleLogin: () => void;
  authenticated: boolean | null;
  setAuth: (authenticated: boolean | null) => void;
  userManager: UserManager;
  handleLogout: any;
};
function App({
  authenticated,
  handleLogin,
  setAuth,
  userManager,
  handleLogout,
}: Props) {
  const [userInfo, setUserInfo] = useState<User | null>(null);
  useEffect(() => {
    if (authenticated === null) {
      userManager
        .signinRedirectCallback()
        .then((user: User) => {
          if (user) {
            setAuth(true);
            setUserInfo(user);
          } else {
            setAuth(false);
          }
        })
        .catch((error: any) => {
          setAuth(false);
        });
    }
    if (authenticated === true && userInfo === null) {
      userManager
        .getUser()
        .then((user) => {
          if (user) {
            setAuth(true);
            setUserInfo(user);
          } else {
            setAuth(false);
          }
        })
        .catch((error: any) => {
          setAuth(false);
        });
    }
  }, [authenticated, userManager, setAuth]);

  const links = authenticated
    ? [
      { link: '/', 'label': 'Home' },
      { link: '/callback', 'label': 'Logout' }
    ]
    : [{ link: '/', 'label': 'Home' }]

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <Notifications />
      <ModalsProvider>
        <div className="App">
          <HeaderResponsive links={links}></HeaderResponsive>
          {authenticated === null && <div>Loading...</div>}
          {authenticated === false && (
            <div>
              <button
                onClick={() => {
                  // Perform the authorization request, including the code challenge
                  handleLogin();
                }}
              >
                Login
              </button>
            </div>
          )}
          {authenticated && userInfo && (
            <>
              {userInfo.profile.name}
              < Outlet />
            </>
          )}
        </div>
      </ModalsProvider>
    </MantineProvider>
  );
}

export default App;
