import { useForm, isNotEmpty } from '@mantine/form';
import { Button, Group, TextInput, NumberInput, Box, Checkbox, NativeSelect } from '@mantine/core';
import { IExpenseInput, IUsers } from './client/model';
import BackendClient from './client/backentClient';
import { useState, useEffect } from 'react';

const backendClient = new BackendClient();

interface ExpenseInputFormProps {
    onSubmit: (newExpenses: IExpenseInput[]) => void
}

function ExpenseInputForm({ onSubmit }: ExpenseInputFormProps) {
    const [findRes, setfindRes] = useState<IUsers>();

    useEffect(() => {
        backendClient.findUsers().then((res) => setfindRes(res))
    }, []);

    const transform = (usersRes: IUsers | undefined) => {
        if (usersRes === undefined) {
            return [];
        }
        return usersRes.users
            .map((user) => (
                { value: user.id, label: user.contact.name }
            ));
    }

    const form = useForm({
        initialValues: {
            completedBy: '',
            timeSpent: 60,
            operation: 'email',
            amount: 150,
            isTaxable: false
        },

        validate: {
            completedBy: isNotEmpty('Enter the user who completed the task'),
            operation: isNotEmpty('Enter the operation name')
        },
    });

    return (
        <Box component="form" maw={400} mx="auto" onSubmit={form.onSubmit((values: IExpenseInput) => onSubmit([values]))}>
            <NativeSelect
                data={transform(findRes)}
                label="Completed by"
                description="This is anonymous"
                withAsterisk
                {...form.getInputProps('completedBy')}
            />
            <NumberInput
                mt="md"
                label="Time spent"
                withAsterisk
                {...form.getInputProps('timeSpent')}
            />
            <TextInput
                label="Operation"
                withAsterisk
                mt="md"
                {...form.getInputProps('operation')}
            />
            <NumberInput
                mt="md"
                label="Amount"
                withAsterisk
                {...form.getInputProps('amount')}
            />
            <Checkbox
                label="taxable"
                mt="md"
                {...form.getInputProps('isTaxable')}
            />
            <Group position="right" mt="md">
                <Button type="submit">Submit</Button>
            </Group>
        </Box>
    );
}

export default ExpenseInputForm;