import FileUploader from "./FileUploader";
import Files from "./Files";
import { IFile } from "./client/model";

interface FileManagerProps {
    caseId: string,
    userId: string,
    files: IFile[],
}

function FileManager({caseId, userId, files}: FileManagerProps) {
    return (
        <>
            <FileUploader caseId={caseId} userId={userId}></FileUploader>
            <Files caseId={caseId} files={files}></Files>
        </>
    );
}

export default FileManager;
