import React, { useState, useEffect, useCallback } from 'react';
import { Text, TextInput, useMantineTheme, TextInputProps, ThemeIcon } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';


interface EditableTextProps extends TextInputProps {
  initialText: string;
  onTextChange?: (text: string) => void;
  fz?: string;
  fw?: number | 'normal' | 'bold' | 'bolder' | 'lighter';
}

function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, delay]);

  return debouncedValue;
}

function EditableText({ initialText, onTextChange, fz = 'md', fw = 'normal', ...props }: EditableTextProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);
  const [isHovered, setIsHovered] = useState(false);
  const theme = useMantineTheme();

  const debouncedText = useDebounce(text, 500);

  const handleClick = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleBlur = useCallback(() => {
    if (text.trim() === '') {
      setText(initialText);
    }
    setIsEditing(false);
    if (text.trim() !== '' && onTextChange) {
      onTextChange(text);
    }
  }, [initialText, onTextChange, text]);

  const handleTextChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  if (isEditing) {
    return (
      <TextInput
        type="text"
        value={text}
        onChange={handleTextChange}
        onBlur={handleBlur}
        style={{
          backgroundColor: theme.colorScheme === 'dark' ? '#2b2b2b' : '#f0f0f0',
          color: theme.colorScheme === 'dark' ? '#ffffff' : '#000000',
          padding: '5px',
          border: 'none',
          borderRadius: '4px',
          fontSize: theme.fontSizes[fz],
          fontWeight: fw,
        }}
        {...props}
      />
    );
  }

  return (
    <Text
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        cursor: 'pointer',
        fontSize: theme.fontSizes[fz],
        fontWeight: fw,
        textDecoration: isHovered ? 'underline dotted' : 'none',
      }}
      {...props}
    >
      {debouncedText}
      {isHovered && (
        <span style={{ float: 'right', marginLeft: '5px' }}>
          <ThemeIcon size="sm">
            <IconEdit />
          </ThemeIcon>
        </span>
      )}
    </Text>
  );
}

export default EditableText;
